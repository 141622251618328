import React from "react"
import "./BookDownload.css"

export default () => (
  <section className="book-download">
    <img
      src="/what-i-wish-i-knew-cover.png"
      alt="What I Wish I Knew Before Learning to Code Ebook Cover"
      className="cover"
    />
    <div className="book-download-content">
      <h2>Want more advice on learning to code? </h2>
      <a href="https://ebook.welearncode.com" className="download-button">
        Download my free Ebook!
      </a>
    </div>
  </section>
)
