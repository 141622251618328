import React from "react"

const AffiliateDisclosure = () => (
  <p>
    <i>
      This post contains affiliate links. If you buy something through those
      links I may earn a small commission at no cost to you. This helps pay for
      the costs associated with running We Learn Code. I promise to only
      recommend products I use and love!
    </i>
  </p>
)

export default AffiliateDisclosure
